.cookie-container {
  margin-top: 65px;
  font-family: "Open Sans", sans-serif;
}

.cookie {
  width: 75% !important;
  margin: auto !important;
}

.cookie-container h1 {
  font-size: 36px;
  color: #333;
  padding: 80px 0 35px 0 !important;
}

.cookie-container p {
  color: #666;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}

.cookie-container b {
  line-height: 3;
}

@media screen and (max-width: 491px) {
  .cookie-container {
    margin-top: 55px !important;
  }

  .cookie {
    width: 90% !important;
  }

  .cookie-container h1 {
    padding: 40px 0 40px 0 !important;
    margin: 0;
  }

  .cookie-container p,
  li {
    line-height: 1.9 !important;
  }
}
.cspbankingservices {
    padding: 40px 0;
    background-image: url('../../../Landing/Home/Assets/dashboard.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
}



.cspbankingservicescontent {
    background-color: #2C5363;
    padding: 4px;
    margin-bottom: 1px;
    cursor: pointer;
}


.cspbankingservicescontent h2 {
    color: #fff;
    letter-spacing: 0px;
    padding: 14px 20px;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    border-radius: 0;
}

.active {
    background-color: #00B087;
    transition: 0.3s;
}


.csp-col-1 {
    padding-right: 20px;
}



.csp-col-2 {
    padding-left: 50px;
}

.csp-content-container {
    transition: opacity 0.3s ease;
}

.csp-content-container.hidden {
    opacity: 0;
}

@media screen and (max-width: 491px) {
    .csp-col-1 {
        position: relative;
        padding-right: 20px;
    }

    .csp-col-2 {
        padding-left: 10px;
        margin-top: 50px;
    }

}

@media screen and (min-width: 492px) and (max-width: 992px) {

    .csp-col-1 {
        padding-right: 20px;
    }



    .csp-col-2 {
        padding-left: 10px;
        padding-top: 20px;
    }

    .cspbankingservicescontent h2 {
        color: #fff;
        letter-spacing: 0px;
        padding: 14px 20px;
        text-transform: capitalize;
        font-size: 13px;
        line-height: 27px;
        margin: 0;
        border-radius: 0;
    }
}
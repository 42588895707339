.main-bankingserives {
    padding: 100px 0;
}

.main-bankingserives h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
}

.all-services {
    padding: 40px 0px;
}

.column-info {
    padding: 30px;
    background-color: #effaff;
    border-radius: 20px;
}

.column-info:hover {
    background-color: #2D5464;
    transition: .3s ease-in-out;
}

.column-info:hover h3 {
    color: #fff;
}

.icon-wrapper {
    text-align: center;
}

.all-services h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding: 20px;
}

@media screen and (max-width: 491px) {
    .main-bankingserives {
        padding: 100px 0;
    }

    .main-bankingserives h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
    }

    .all-services {
        padding: 20px !important
    }

    .column-info {
        padding: 30px;
        background-color: #effaff;
        border-radius: 20px;
    }

    .column-info:hover {
        background-color: #2D5464;
        transition: .3s ease-in-out;
    }

    .column-info:hover h3 {
        color: #fff;
    }

    .icon-wrapper {
        text-align: center;
    }

    .all-services h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        padding: 10px;
    }
}

.enterprise-container{
  padding: 48px 0;
}
.enterprise-main-container {
  width: 90%;
  margin: auto;
}

.enterprise-main-container img {
  width: 160px;
}

.enterprise-main-container h2 {
  color: #2c5363;
  font-size: 35px;
  font-weight: 700;
}

.enterprise-img {
  height: 50px !important;
  width: 150px !important;
}

@media screen and (max-width: 600px) {

  .enterprise-container {
    padding: 30px 0 10px 0 !important;
  }
  .enterprise-img-col{
    padding: 15px 10px !important;
  }

  .enterprise-main-container img {
    width: 120px;
  }

  .enterprise-img {
    height: 40px !important;
    width: 110px !important;
  }

  .enterprise-main-container h2 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px !important;
  }
}

.service-home-container {
  padding: 100px 0 50px 0;
  background-size: cover;
  background-color: #2c5363;
}

.services-main-container {
  width: 90%;
  margin: auto;
}

.services-main-container .service-heading h1 {
  font-size: 43px !important;
  font-weight: 600 !important;
}

.services-main-container .service-heading-search input {
  width: 500px;
  height: 45px;
  border-radius: 2px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1) !important;
  transition: transform 0.3s ease;
  transform-origin: 50% 50%;
  color: white;
}

.services-main-container .service-heading-search input:focus {
  transform: scale(1.05);
  box-shadow: none;
}

.services-main-container .service-heading-search .search-input-icon {
  right: 0;
}

.services-main-container .service-heading-search .form-control::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
}

.services-main-container .service-heading-search .form-control:-ms-input-placeholder {
  color: white;
}

.services-main-container .service-heading-search .form-control::-ms-input-placeholder {
  color: white;
}

.services-main-container .service-p p {
  font-size: 18px;
}

.services-main-container .service-home-icon img {
  width: 80px;
}

.services-main-container .service-home-icon p {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 600;
}

.services-main-container a {
  text-decoration: none !important;
  color: white;
}

.services-home-bottom-heading {
  background-color: #fff;
  padding: 20px 0;
}

.service-home-container .service-new-tab {
  width: 80%;
  bottom: -8%;
  left: 10%;
}

.service-home-container .service-new-tab .services-tab-list {
  margin: 12px 0;
  padding: 0;
}

.service-home-container .service-new-tab .services-tab-list li,
a {
  list-style-type: none !important;
  text-decoration: none !important;
  color: #2c5363 !important;
}

.service-home-container .service-new-tab .services-tab-list .service-tab-div {
  width: 125px;
  border-radius: 3px;
  padding: 5px 10px;
  box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px,
    rgb(9 30 66 / 8%) 0px 0px 0px 1px !important;
}

.service-home-container .service-new-tab .services-tab-list.active {
  background-color: #2c5363;
}

@media screen and (min-width: 1550px) {
  .service-home-container .service-new-tab .services-tab-list .service-tab-div {
    width: 140px;
  }
}

@media screen and (max-width: 1400px) {
  .service-home-container .service-new-tab .services-tab-list-bank {
    margin-left: 35%;
  }

  .service-home-container .service-new-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    /* width: 70%; */
  }

  .service-home-container .service-new-tab .services-tab-list .service-tab-div {
    margin: 0 10px;
    width: 150px;
  }
}

@media screen and (max-width: 991px) {
  .service-home-container .service-new-tab .services-tab-list-bank {
    margin-left: 50%;
  }

  .services-main-container .service-heading h1 {
    font-size: 35px !important;
  }
}


@media screen and (max-width: 600px) {
  .services-main-container .service-heading h1 {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 491px) {

  .service-home-container {
    padding: 50px 0 30px 0;
  }


  .service-home-container .service-new-tab .services-tab-list-bank {
    margin-left: 75%;
  }

  .services-main-container .service-heading h1 {
    font-size: 25px !important;
    line-height: 1.4;
  }

  .services-main-container .service-heading-search input {
    width: 300px;
  }

  .service-home-container .service-new-tab .services-tab-list .service-tab-div {
    /* margin: 0 10px; */
    width: 130px;
  }
}
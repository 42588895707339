.financial-home-container {
    margin-top: 61px;
    margin: auto;
    align-items: center;
    padding: 80px 110px;
  }
  
  
  .investment-left-content h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 100px;
  }
  
  .investment-left-content span {
    color: #00b087;
  }
  
  .investment-left-content p {
    font-weight: 500;
    font-size: 17px;
  }
  
  /* .investment-left-content p {
      width: 600px;
    } */
  
  .investment-left-content .pdiv {
    margin-top: 30px;
  }
  
  /* .financial-home-container img {
      width: 400px;
    } */
  
  .about-home-content {
    width: 90%;
    text-align: justify;
  }
  
  .investment-home-list ul {
    padding: 0 0 0 20px;
  }
  
  .investment-home-list li {
    font-size: 18px;
    color: #000;
    list-style: circle;
    padding: 5px;
  }
  
  .investment-home-img {
    width: 600px !important;
    height: 450px !important;
    /* margin-right: 50px; */
  }
  
  .small-devices-about-heading {
    display: none;
  }
  
  @media screen and (max-width: 1600px) {
    .investment-home-img {
      width: 500px;
      height: 300px;
      /* margin-right: 50px; */
    }
  }
  
  @media screen and (max-width: 1200px) {
    .investment-home-img {
      width: 400px !important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .financial-home-container {
      margin-top: 55px;
      padding: 0px 20px;
    }
  
    .investment-left-content h2 {
      font-size: 35px;
      text-align: center;
    }
  
    .investment-left-content p {
      text-align: center;
      font-size: 16px;
    }
  
    .banking-home-content {
      width: 100%;
    }
  
    .investment-home-img {
      width: 425px;
      margin: 0 !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .banking-main-container {
      padding: 30px 0 !important;
    }
  
    .investment-home-img {
      width: 400px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .banking-main-container {
      padding: 30px 0 !important;
    }
  
    .investment-left-content h2 {
      font-size: 30px;
      text-align: center;
    }
  
    .investment-home-img {
      width: 350px;
      margin: 0 !important;
    }
  }
  
  @media screen and (max-width: 491px) {
    .investment-home-img {
      width: 450px !important;
      height: 200px !important;
      margin-right: 100px;
    
    }
  
    .financial-home-container {
      margin-top: 55px;
      padding: 20px;
    }
  
  
    .banking-main-container {
      padding: 20px 0 !important;
    }
  
    .investment-left-content h2 {
      font-size: 30px;
      text-align: center;
    }
  
    .investment-left-content p {
      font-size: 15px;
    }
  
    .large-devices-about-heading {
      display: none !important;
    }
  
    .small-devices-about-heading {
      display: block !important;
    }
  
    .investment-home-img {
      width: 250px !important;
      margin: 0 !important;
    }
  }
  
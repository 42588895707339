.abc-card {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    /* backdrop-filter: blur(10px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}
.abc-card2{
    border: 1px solid #eaeaea;
    border-radius: 2px;
    /* backdrop-filter: blur(5px); */
    -webkit-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.abc-card2 .register-verified-icon {
    height: 5rem;
    width: 5rem;
}

.abc-card .register-verified-icon {
    height: 5rem;
    width: 5rem;
}


.abc-para {
    line-height: 1.2;
    font-size: 0.9rem;
}

.abc-card h4 {
    color: #003399;
}

.abc-card .dash-btn {
    background-color: #0055ff;
    color: white;
}
.Spinner{
        position: absolute; 
        top: 50%;
        left: 49%;
}
.Alertimg{
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 10px;

}
.container2{
    padding: 8rem 0 5rem 0;
    /* filter: blur(0.5px); */
    position: relative;
  
   height: 62vh;

}
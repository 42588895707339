.register-form .modal-content {
  border-radius: 20px !important;
  width: 100px !important;
}

.register-form .modal-content .otp-container .btn-close {
  right: 10px;
  top: 10px;
  height: 20px;
  width: 20px;
  background-color: #f8f9fa;
  border-radius: 50%;
  font-size: 10px;
}

.register-form .modal-content .otp-container .btn-close:hover {
  color: white !important;
  background-color: #2c5363;
}

.otp-container .row .otp-box {
  height: 320px;
  width: 320px;
  background-color: white;
  border-radius: 20px;
}

.otp-container .row .otp-box .otp-icon .otp-icon-img {
  height: 65px !important;
  width: 65px !important;
}

.otp-container .row .otp-box .otp-icon {
  background-color: #2c5363;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.otp-container .row .otp-box h5 {
  font-size: 1rem;
}

.otp-container .row .otp-box input {
  height: 3rem;
  width: 3rem;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 10px 0 10px;
}

.otp-container .row .otp-box .opt-btn {
  background-color: #2c5363;
  width: 15.7rem !important;
  height: 2.6rem;
  font-size: 1.1rem;
  font-weight: 500;
}

@media screen and (max-width: 1400px) {
  .accept-policy-terms {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 491px) {
  .accept-policy-terms {
    display: flex;
    flex-direction: column;
  }
  .register-form .modal-content .otp-container .btn-close {
    right: 10px !important;
    top: 10px !important;
    height: 20px !important;
    width: 20px !important;
  }

  .otp-box {
    overflow: hidden;
  }

  .otp-icon {
    position: absolute !important;
    top: 7%;
  }

  .otp-box h5 {
    position: absolute !important;
    top: 37%;
    right: 37%;
  }

  .otp-input-mobile {
    position: absolute;
    top: 45%;
    right: 0%;
  }

  .otp-input-verify {
    position: absolute;
    bottom: 7%;
    right: 3%;
  }
}

.contact-container {
  margin-top: 61px !important;
  background-image: url('../../Components/Landing/Home/Assets/dashboard.svg ');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: 66px;
}

.contact-back {
  padding: 100px 0 !important;
  background-image: url(./contact-back.png);
  /* background-image: url(./media.png); */
  /* color: #2c5363; */
  color: white;
}

.contact-back h1 {
  font-size: 40px;
  font-weight: 700;
}

.contact-back p {
  font-size: 18px;
  margin: 0;
}

.contact-main-container {
  width: 80%;
  margin: auto !important;
}

.contact-main-container h3 {
  font-size: 20px;
  font-weight: 700;
  margin-left: 48px;
}

.contact-main-container img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.contact-main-container .btn {
  background-color: #16b287;
  font-size: 18px;
  font-weight: 700;
  width: 450px !important;
  box-shadow: 0 4px 12px 0 #b5b5b5 !important;
  margin-left: 13px;
}

.contact-main-container .contact-form input {
  width: 450px !important;
  background-color: #f3f3f3;
  border: none;
  border-radius: 0;
  margin-bottom: 12px;
}

.contact-main-container .contact-form input:focus {
  box-shadow: none;
}

.contact-main-container .contact-form textarea:focus {
  box-shadow: none;
}

.contact-main-container .contact-form textarea {
  width: 450px;
  background-color: #f3f3f3;
  border: none;
  border-radius: 0;
}

.contact-main-container .contact-form label {
  font-size: 14px;
  font-weight: 700;
}

.form {
  padding-left: 80px;
  border-left: 1px solid #e0dede;
}

.contact-us-support {
  margin: 30px 0 0 30px;
}

.puzzle-img {
  height: 47px !important;
  width: 47px !important;
}
.contact-us-img-li {
  padding: 0 48px;
  margin-top: 48px;
}

.contact-btn-mobile {
  padding: 0 !important;
}

@media screen and (max-width: 992px) {
  .contact-container {
    margin-top: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  .contact-back {
    padding: 50px !important;
  }

  .contact-back h1 {
    font-size: 35px;
  }
  

  .contact-back p {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .contact-back {
    padding: 50px 20px !important;
  }

  .contact-back h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 491px) {
  .contact-main-container {
    width: 90%;
    margin: 0;
    padding: 0 20px !important;
  }

  .contact-h3 h3 {
    margin: 0 !important;
    padding-bottom: 20px;
  }

  .contact-back {
    padding: 50px 15px !important;
  }

  .contact-back h1 {
    font-size: 30px !important;
    margin: 0;
  }

  .contact-back p {
    font-size: 16px;
    padding: 20px 0px;
    text-align: center;
    margin: 0;
  }

  .contact-us-img-li {
    padding: 0;
    margin-top: 0;
  }

  .contact-us-img-li img {
    height: 30px;
    width: 30px;
  }

  .contact-us-img-li p {
    font-size: 15px;
  }

  .contact-main-container .contact-form input {
    width: 320px !important;
    margin: 0 !important;
  }

  .contact-main-container .contact-form textarea {
    width: 320px;
  }

  .contact-main-container .btn {
    font-size: 16px;
    height: 35px;
    width: 320px !important;
    margin-left: 0 !important;
  }

  .contact-main-container .contact-form label {
    font-size: 13px;
  }

  .form {
    display: flex;
    justify-content: center !important;
    padding-left: 0 !important;
    border-left: none;
  }

  .contact-form {
    width: 340px !important;
    margin: 0 !important;
    padding: 20px !important;
  }

  .contact-form .row {
    margin: 0 !important;
    width: 350px !important;
  }

  .contact-form .row .col-12 {
    padding: 0 !important;
  }

  .contact-btn-mobile {
    padding: 0 12px !important;
  }
}

.refund-container {
  margin-top: 65px;
  font-family: "Open Sans", sans-serif;
}

.refund {
  width: 75% !important;
  margin: auto !important;
}

.refund-container h1 {
  font-size: 36px;
  color: #333;
  padding: 80px 0 35px 0 !important;
}

.refund-container p {
  color: #666;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}

@media screen and (max-width: 491px) {
  .refund-container {
    margin-top: 55px;
  }

  .refund {
    width: 90% !important;
  }

  .refund-container h1 {
    padding: 40px 0 40px 0 !important;
  }

  .refund-container p {
    line-height: 1.9 !important;
  }
}

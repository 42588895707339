.enquiry-top-container {
  background-color: #ade4d5;
  padding: 0 20px;
}

.enquiry-top-container .enquiry-top h3,
.enquiry-no {
  font-size: 28px;
  color: #2c5363;
}

.enquiry-top-container .enquiry-no,
.enquiry-span {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .enquiry-top-container .enquiry-top h3,
  .enquiry-no {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  enquiry-top-container .enquiry-top h3,
  .enquiry-no {
    font-size: 22px !important;
  }
}


@media screen and (max-width: 491px) {
  .enquiry-top-container .enquiry-top h3,
  .enquiry-no {
    font-size: 18px !important;
  }
}

.footer {
  background-color: #283646;
  padding-top: 48px;
}

.footer-container {
  width: 80%;
  margin: auto;
}

.footer h5 {
  color: rgb(22, 178, 135);
  font-weight: 700;
}

.footer li {
  list-style-type: none;
  line-height: 1.8;
}

.footer .footer-container a,
ul {
  text-decoration: none;
  color: white !important;
}

.footer .footer-container a:hover {
  color: rgb(22, 178, 135);
  transition: 1s;
}

.footer .adr {
  color: white;
}

.footer .reach img {
  width: 20px;
  margin-top: 5px;
}

.footer .bottom-footer {
  border-top: 0.1rem solid rgb(22, 178, 135);
  font-size: 13px;
  text-align: center;
  color: gainsboro;
}

.bottom-footer .fa {
  margin-right: 7px;
}

.footer-copyright {
  font-size: 12px;
  background-color: #1a2531;
  color: gainsboro;
  padding: 10px 0;
}

.footer-copyright .large-device-footer-copyright a,
.footer-copyright .small-device-footer-copyright a {
  cursor: pointer !important;
  text-decoration: underline !important;
  color: #0d6efd !important
}

.footer-icons img {
  width: 40px;
  margin: 0 10px 0 10px;
  /* padding: 0px 10px 0px 10px; */
  /* padding: 5px; */
}

.bottom-footer img:hover {
  color: rgb(22, 178, 135);
}

/* .footer-head {
  padding-left: 10px;
  border-left: 2px solid rgb(22, 178, 135);
} */

.footer-links a {
  padding: 0 5px 0 5px;
}

.footer-container .last {
  padding-left: 60px !important;
}

.iso-img-footer {
  width: 150px !important;
  bottom: 0%;
  left: 2%;
}

.iso-img-footer-2 {
  width: 130px !important;
  right: 0%;
  bottom: 7% !important;
}

.small-devices-footer {
  display: none;
}

.small-device-footer-copyright {
  display: none !important;
}

.large-device-footer-copyright {
  display: block !important;
}


@media screen and (max-width: 1400px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: 0%;
    bottom: 5% !important;
  }
}

@media screen and (max-width: 1200px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: 0% !important;
    bottom: 6% !important;
  }
}

@media screen and (max-width: 992px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: -5% !important;
    bottom: 0% !important;
  }

  .footer .footer-container li {
    font-size: 15px !important;
  }


  .small-device-footer-copyright {
    display: block !important;
  }

  .large-device-footer-copyright {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: 0% !important;
    bottom: 2% !important;
  }

  .bottom-footer {
    display: flex;
    flex-direction: column;
  }

  .footer .footer-container li {
    font-size: 14px !important;
  }


  .large-device-footer-copyright {
    display: none !important;
  }

  .small-device-footer-copyright {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: 5% !important;
    bottom: 30% !important;
  }

  .footer {
    padding-top: 30px;
  }

  .footer h5 {
    font-size: 18px;
  }

  .expand-icon {
    height: 20px;
  }

  .large-device-footer {
    display: none !important;
  }

  .small-devices-footer {
    display: block;
  }

  .iso-img-footer-2 {
    width: 120px !important;
    right: 5% !important;
    bottom: 5% !important;
  }

  .footer .footer-container li {
    font-size: 13px !important;
  }


  .small-device-footer-copyright {
    display: block !important;
  }

  .large-device-footer-copyright {
    display: none !important;
  }
}

@media screen and (max-width: 491px) {
  .iso-img-footer-2 {
    width: 120px !important;
    right: 0% !important;
    bottom: 5% !important;
  }


  .small-device-footer-copyright {
    display: block !important;
  }

  .large-device-footer-copyright {
    display: none !important;
  }
}

.footer-desclaimer {
  border-top: 0.1rem solid rgb(22, 178, 135);
}

.footer-desclaimer-text {
  font-size: 12px !important;
  text-align: center;
  color: white;
  margin: 0;
  padding: 0;
}
.services {
  margin-top: 61px;
  overflow-x: hidden;
}

.services-side-img img {
  width: 600px !important;
  margin-left: 100px !important;
}

@media screen and (max-width: 992px) {
  .services {
    margin-top: 55px;
  }
  
  .payment-container,
  .financial-container,
  .travel-container,
  .loan-container,
  .insurance-container,
  .goverment-container,
  .legal-container {
    padding: 80px 0 60px 0 !important;
  }
  .banking-main-container h1,
  .payment-main-container h1,
  .financial-main-container h1,
  .travel-main-container h1,
  .insurance-main-container h1,
  .goverment-main-container h1,
  .loan-main-container h1,
  .legal-main-container h1 {
    font-size: 50px !important;
    text-align: center;
  }
  .banking-main-container h5,
  .payment-main-container h5,
  .financial-main-container h5,
  .travel-main-container h5,
  .loan-main-container h5,
  .insurance-main-container h5,
  .goverment-main-container h5,
  .legal-main-container h5 {
    font-size: 16px !important;
    margin: 0 !important;
  }

  .services-side-img img {
    width: 500px !important;
    padding-top: 50px;
    margin: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .payment-container,
  .financial-container,
  .travel-container,
  .loan-container,
  .insurance-container,
  .goverment-container,
  .legal-container {
    padding: 60px 0 60px 0 !important;
  }

  .banking-main-container h1,
  .payment-main-container h1,
  .financial-main-container h1,
  .travel-main-container h1,
  .insurance-main-container h1,
  .goverment-main-container h1,
  .loan-main-container h1,
  .legal-main-container h1 {
    font-size: 45px !important;
    text-align: center;
  }
  .banking-main-container h5,
  .payment-main-container h5,
  .financial-main-container h5,
  .travel-main-container h5,
  .loan-main-container h5,
  .insurance-main-container h5,
  .goverment-main-container h5,
  .legal-main-container h5 {
    font-size: 16px !important;
    margin: 0 !important;
  }

  .services-side-img img {
    width: 450px !important;
    padding-top: 40px;
    margin: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .payment-container,
  .financial-container,
  .travel-container,
  .loan-container,
  .insurance-container,
  .goverment-container,
  .legal-container {
    padding: 50px 0 50px 0 !important;
  }
  .banking-main-container h1,
  .payment-main-container h1,
  .financial-main-container h1,
  .travel-main-container h1,
  .insurance-main-container h1,
  .goverment-main-container h1,
  .loan-main-container h1,
  .legal-main-container h1 {
    font-size: 38px !important;
    text-align: center;
  }
  .banking-main-container h5,
  .payment-main-container h5,
  .financial-main-container h5,
  .travel-main-container h5,
  .loan-main-container h5,
  .insurance-main-container h5,
  .goverment-main-container h5,
  .legal-main-container h5 {
    font-size: 15px !important;
    margin: 0 !important;
  }
  .services-side-img img {
    width: 425px !important;
    padding-top: 30px;
    margin: auto !important;
  }
}

@media screen and (max-width: 491px) {
  .services-side-img img {
    width: 400px !important;
    padding-top: 30px;
    margin: auto !important;
  }
}

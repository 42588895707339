.aeps-content{
    text-align: center;
}
.aeps-content h1 {
    font-weight: 700;
    color: #2C5363;
    text-align: left;
    margin-left: 20px;
    font-size: 32px;
}


.aeps-content img {
    width: 56px;
}

.aeps-content h3 {
    font-size: 13px;
    padding: 5px;
    font-weight: 400;
    margin-bottom: 0px;
    padding-bottom: 30px;
}

.aeps-all-content {
    text-align: left;
}

.aeps-all-content h4 {
    font-size: 16px;
    padding: 5px;
    color: #333 !important;
    font-weight: 400 !important;
    line-height: 25px;
}

 ul {
    padding: 0 0 0 3px !important;
}

.bottom-list li {
    font-size: 16px;
    color: #333;
    list-style: none;
    margin-bottom: 0px;
}
.bottom-list li p{
    margin-bottom: 0px;
}

.loginlink p{
    text-align: left;
    color: #00B087;
}

.what-is-investment {
    background-color: #EBFFFA;
    padding: 40px 110px;
}

.whatisinvestment-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.what-is-investment h1 {
    font-size: 32px;
    font-weight: bold;
}

.what-is-investment span {
    color: #00B087;
}

.whatisinvestment-content p {
    font-size: 20px;
    font-weight: "200";
    text-align: center;
    padding: 10px 170px;
}

@media screen and (max-width: 491px) {
    .what-is-investment {
        background-color: #EBFFFA;
        padding: 50 10px;
        margin-top: 80px;
    }

    .whatisinvestment-heading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .what-is-investment h1 {
        font-size: 20px;
        font-weight: bold;
    }

    .what-is-investment span {
        color: #00B087;
    }

    .whatisinvestment-content p {
        font-size: 12px !important;
        font-weight: "200";
        text-align: center;
        
    }
}

@media screen and (max-width: 992px) {

    .what-is-investment {
        background-color: #EBFFFA;
        padding: 50 10px;
        margin-top: 80px;
    }

    .whatisinvestment-heading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .what-is-investment h1 {
        font-size: 30px;
        font-weight: bold;
    }

    .what-is-investment span {
        color: #00B087;
    }

    .whatisinvestment-content p {
        font-size: 20px;
        font-weight: "200";
        text-align: center;
        padding: 0 20px;
    }
}
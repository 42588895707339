.resourses-container .blog-card-container .blog-cards {
  height: 420px;
  width: 400px;
  background-color: white;
  box-shadow: 0 3px 6px #0000001a;
}
.resourses-container{
  background-image: url('../../../Components/Landing/Home/Assets/dashboard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: 50px;
}
.resourses-container .blog-card-container {
  padding: 100px 0 48px 0;
  
}

.resourses-container .blog-card-container .blog-cards img {
  width: 100%;
  border-radius: 5px;
  transition: transform 0.3s ease;
  transform-origin: 50% 50%;
}

.resourses-container .blog-card-container .blog-cards img:hover {
  transform: scale(1.1);
}

.resourses-container .blog-card-container .blog-cards h5 {
  font-size: 18px;
  font-weight: 700;
  color: #2c5363;
}

.resourses-container .blog-card-container .blog-cards h5:hover {
  color: #16b287;
  transition: 0.5s;
}

.resourses-container .blog-card-container .blog-cards p {
  font-size: 14px;
  color: #6f6f6f;
  width: 350px;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.resourses-container .blog-card-container .blog-cards .btn {
  /* background-color: #8eb5c5; */
  height: 30px;
  width: 170px;
  font-weight: 600;
  color: #2c5363;
  overflow: hidden !important;
}

.resourses-container .blog-card-container .knowledge-main-container h3 span {
  color: #16b287;
}

.blogs-cards-imgs {
  height: 220px !important;
  width: 100% !important;
}

.resourses-container .blog-card-container .knowledge-main-container .badge {
  color: #40788f !important;
  background-color: #35526119 !important;
  font-size: 13px;
  padding: 10px;
  width: 100px;
  overflow: hidden !important;
}

@media screen and (max-width: 491px) {
  .resourses-container .blog-card-container .blog-cards {
    height: 420px;
    width: 320px;
    margin: 30px 0 !important;
  }

  .blogs-cards-imgs {
    height: 180px !important;
    width: 100% !important;
  }

  .resourses-container .blog-card-container {
    padding: 50px 0 48px 0;
  }

  .resourses-container .blog-card-container .blog-cards p {
    width: 280px;
    height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.login-container {
  padding: 120px 0 100px 0;
  margin-top: 61px;
  background-image: url(./Login-new-back.svg);
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 160%;
  margin-left: -20px;
}

.login-main-container {
  width: 80%;
  margin: auto;
}

.login-main-container .login-heading-content h1 {
  font-weight: 700 !important;
  color: white;
}

.login-main-container .login-heading-content span {
  color: #16b287;
}

.login-main-container .login-heading-content .login-sub-heading {
  font-size: 17px;
}

.login-main-container .login-heading-content li {
  list-style-type: none;
  color: white;
}

.login-main-container .login-left-img img {
  margin-left: 70px;
  width: 400px;
  height: 400px;
}

.login-main-container .login-form {
  width: 400px;
  height: 450px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.join-main-container .login-form {
  padding: 0 65px;
}

.login-main-container .login-form .login-form-less {
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px;
  background-color: black;
}

.login-main-container .login-form .login-form-less img {
  height: 30px !important ;
  width: 30px !important;
}

.login-main-container .login-form .login-form h5 {
  color: #2c5363;
}

.login-main-container .login-form input {
  height: 45px;
  border-radius: 3px;
  font-size: 14px;
}

.login-main-container .login-form input:focus {
  border-color: #2c5363;
  box-shadow: none;
}

.login-main-container .login-form button:focus {
  box-shadow: none;
}

.login-main-container .login-form .input {
  padding-left: 40px;
}

.login-main-container .login-form .login-btn {
  border-radius: 0;
  background-color: #2c5363;
  font-weight: 600;
  font-size: 14px;
}
.login-main-container .login-form .login-btn:hover {
  background-color: #21414e;
}

.login-main-container .login-form .or .border-p {
  width: 100%;
  text-align: center;
  color: #999;
  border-bottom: 1px solid rgb(185, 185, 185);
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.login-main-container .login-form .or .border-p span {
  background: #fff;
  padding: 0 30px;
}

.login-main-container .login-form .show-btn {
  font-size: 13px;
  color: #16b287;
  cursor: pointer !important;
}

.login-main-container .login-form .fa-eye {
  margin-right: 5px;
  font-size: 18px;
}

.login-main-container .login-form .social-ac {
  width: 150px;
}

.login-main-container .login-form .login-p,
.login-a {
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}

.login-main-container .login-form .csp-ac p {
  font-size: 15px;
  padding-top: 16px;
}

.login-main-container .login-form .term-condition a {
  font-size: 13px;
}

.login-main-container .login-form img {
  width: 40px !important;
  height: 40px !important;
  /* height: 40px; */
  top: 2px;
  padding: 10px;
}

.login-main-container .login-form .email-ico img {
  width: 39px !important;
  height: 39px !important;
  top: 2px;
  padding: 10px;
}

.login-container .input:focus {
  border-color: #2c5363;
  box-shadow: none;
}

.login-container .input {
  font-size: 14px;
  padding-left: 40px;
  height: 45px;
}

.login-container .login-otp-box .modal-body img {
  width: 39px !important;
  height: 39px !important;
  top: 2px;
  padding: 10px;
}

.login-main-container .login-form .address-ico img {
  width: 41px !important;
  height: 41px !important;
  top: 2px;
  padding: 10px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

.login-main-container .login-img-content {
  margin-top: 90px;
}

.login-main-container .login-img-content strong {
  font-size: 18px;
  color: #2c5363;
}

.login-main-container .login-img-content img {
  width: 120px;
}

.login-otp-btn {
  background-color: #2c5363 !important;
}

.btn-close-ico {
  height: 35px !important;
  width: 35px !important;
  padding: 0 !important;
  cursor: pointer;
}

.Toastify__close-button {
  background-color: #999;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.Toastify__close-button svg {
  height: 14px !important;
  width: 14px !important;
}

@media screen and (max-width: 992px) {
  .login-container {
    margin-top: 55px;
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    padding: 100px 0px !important;
    margin: 55px 0px 0px 0px !important;
    background-color: #2c5363;
  }
  .login-main-container .login-heading-content {
    display: none !important;
  }

  .login-form-small-device {
    padding: 0 !important;
  }

  .login-main-container .login-form {
    width: 330px;
    height: 410px;
  }
  .login-main-container .login-form .show-btn {
    font-size: 12px;
    color: #16b287;
  }

  .login-main-container .login-form .csp-ac p {
    padding: 0 !important;
  }
}
.ninth-section .slick-slide {
    height: auto;
}

.ninth-section .slick-prev {
    left: 1180px;
}

.ninth-section .slick-next {
    right: 10px;
}

.ninth-section .slick-prev:before {
    content: '<';
}

.ninth-section .slick-next:before {
    content: '>';
}

.ninth-section .slick-prev,
.ninth-section .slick-next {
    height: 40px;
    width: 40px;
    background-color: #2c5363;
    border-radius: 50%;
    top: 100% !important;
    margin-top: 60px;
}


.ninth-section .slick-prev:before,
.ninth-section .slick-next:before {
    color: #fff !important;
    font-size: 20px !important;
}

.ninth-section .slick-prev:hover,
.ninth-section .slick-prev:focus,
.ninth-section .slick-next:hover,
.ninth-section .slick-next:focus {
    color: #fff;
    background-color: #2c5363;
}

.ninth-section .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1;
}
.about-new-banner {
    background-image: url(https://img.freepik.com/free-photo/modern-office-space-with-desktops-with-modern-computers-created-with-generative-ai-technology_185193-110089.jpg?w=1480);
    background-size: 100% 100%;
    background-repeat: repeat-x no-repeat;
    background-position: right center;
    margin-top: 66px;
}

.banner-section {
    height: calc(100vh - 66px);
}

.arrow-btn:hover span {
    transform: translateX(10px);
}

.third-section-img-hover {
    border-radius: 20px;
}

.third-section-img-hover img {
    transition-duration: 0.3s;
}

.third-section-img-hover:hover img {
    transform: scale(1.05);
}

.about-side-headings {
    transition-duration: 0.3s
}

.about-side-headings:hover {
    background-color: #b5c8c729;
    border-color: #6c8b95;
    backdrop-filter: blur(4px);
}

.about-join-banner{
    background-image: url(https://img.freepik.com/free-photo/group-people-working-out-business-plan-office_1303-15872.jpg?w=1380);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    
}


.blur-about-container{
    background: rgb(255,0,0);
    background: linear-gradient(90deg, #fff 15%, transparent 100%);

}
.forthsection-content{
    padding: 0 114px;
}
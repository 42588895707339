.mission-container {
    padding: 40px 0;
  }
  
  .mission-main-container {
    width: 60%;
    margin: auto;
  }
  
  .mission-main-container h2 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
  }
  
  .mission-main-container h2 span {
    border-bottom: 5px solid #00b087;
  }
  
  .mission-main-container .about-card {
    height: 280px;
    /* width: 450px; */
    width: 430px;
    border-radius: 10px;
    box-shadow: 16px 12px 28px #6363631a !important;
  }
  
  .about-card .about-card-content .icon .flaticon-values {
    font-size: 70px;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .hr {
    border-top: 2px solid #00b087;
    width: 230px;
  }
  
  .about-card-content h5 {
    font-size: 24px;
    font-weight: 600;
  }
  
  .about-card-content p {
    font-size: 14px;
  }
  
  .about-card-content p span {
    color: #2c5362;
    font-weight: 500;
  }
  
  .about-card .rotate-text-mission h5 {
    opacity: 0.03;
    transform: rotate(270deg);
    font-size: 60px;
    font-weight: 300;
    line-height: 1.2;
  }
  .about-card .rotate-text-mission {
    top: 37%;
    right: -20%;
  }
  
  .about-color-card {
    background-color: #ade4d5;
  }
  
  .about-card .rotate-text-vision h5 {
    opacity: 0.03;
    transform: rotate(270deg);
    font-size: 60px;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .about-card .rotate-text-vision {
    top: 35%;
    right: -15%;
  }
  
  .about-mission-img {
    height: 70px;
    width: 70px;
  }
  
  .mission-outer-padding{
    padding: 48px 0 !important;
  }
  
  @media screen and (max-width: 1500px) {
    .mission-main-container .about-card {
      height: 280px;
      width: 400px !important;
    }
  }
  
  
  @media screen and (max-width: 768px) {
    .mission-main-container h2 {
      font-size: 35px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .mission-container {
      padding: 70px 0;
    }
  
    .mission-main-container h2 {
      font-size: 30px;
      margin: 0;
    }
  
    .mission-main-container h2 span {
      border-bottom: none;
    }
  }
  
  @media screen and (max-width: 491px) {
    .mission-main-container h2 {
      font-size: 25px;
      text-align: center;
      line-height: 1.4;
    }
  
    .mission-outer-padding{
      padding: 30px 0 !important;
    }
    .mission-main-container .about-card {
      height: 300px !important;
    }
  
    .about-mission-img {
      height: 40px;
      width: 40px;
    }
    .hr {
      border-top: 2px solid #00b087;
      width: 160px;
    }
    .about-card-content h5 {
      font-size: 22px;
    }
    .about-card-content p {
      font-size: 13px;
    }
  
    /* rotated text */
  
    .about-card .rotate-text-mission,
    .rotate-text-vision {
      display: none;
    }
  }
  
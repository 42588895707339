.Values-container {
  background-image: url('../../../Components/Landing/Home/Assets/dashboard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  padding: 40px 0;
}

.Values-main-container {
  width: 75%;
  margin: auto;
}

.Values-main-container h2 {
  font-size: 40px;
  font-weight: 700;
  color: #2c5362;
  padding-bottom: 48px;
}

.Values-main-container h2 span {
  border-bottom: 5px solid #00b087;
}

.Values-container .Values-card {
  box-shadow: 16px 12px 28px #6363631a !important;
  border-radius: 10px;
  background-color: white;
  height: 380px;
}

.Values-container .Values-color-card {
  background-color: #2c5363;
  box-shadow: 16px 12px 28px #6363631a !important;
  border-radius: 10px;
  color: white;
  height: 380px;
}

.Values-container .Values-color-card h5 {
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 10px 0;
}

.Values-container .Values-card .csp-heading h5 {
  font-size: 28px;
  font-weight: 600;
  color: #2c5363;
  margin: 0;
  padding: 10px 0;
}

.Values-container .csp-card-icons {
  padding: 48px 0;
}

.csp-card-icons .flaticon-values {
  font-size: 70px;
}

.flaticon-color {
  color: #2c5363;
}

.Values-container .Values-cards .icon-p {
  font-size: 14px;
  padding: 18px 14px !important;
}

@media screen and (max-width: 1600px) {
  .Values-container .Values-card {
    height: 390px;
  }

  .Values-container .Values-color-card {
    height: 390px;
  }
}

@media screen and (max-width: 1200px) {
  .Values-container .Values-card {
    height: 430px;
  }

  .Values-container .Values-color-card {
    height: 430px;
  }

  .Values-container .Values-color-card h5 {
    font-size: 25px;
  }

  .Values-container .Values-card .csp-heading h5 {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .Values-main-container h2 {
    font-size: 35px;
  }

  .Values-main-container h2 span {
    border-bottom: none;
  }

  .Values-container .Values-color-card h5 {
    font-size: 28px;
  }

  .Values-container .Values-card .csp-heading h5 {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .Values-main-container h2 {
    font-size: 30px;
    padding-bottom: 20px !important;
    margin: 0 !important;
  }

  .Values-container .Values-cards {
    padding: 30px 0 !important;
  }

  .Values-container .Values-card,
  .Values-container .Values-color-card {
    height: 300px;
    padding: 20px 0 !important;
  }

  .Values-container .csp-card-icons {
    padding: 25px 0;
  }

  .Values-container .Values-card .csp-heading h5,
  .Values-container .Values-color-card .csp-heading h5 {
    font-size: 24px;
  }

  .Values-container .Values-cards .icon-p {
    font-size: 12px;
    padding: 14px;
  }
}

@media screen and (max-width: 491px) {
  .Values-main-container h2 {
    font-size: 25px;
    padding-bottom: 20px !important;
    margin: 0 !important;
  }

  .about-mission-img {
    height: 40px;
    width: 40px;
  }
  .hr {
    border-top: 2px solid #00b087;
    width: 160px;
  }
  .about-card-content h5 {
    font-size: 22px;
  }
  .about-card-content p {
    font-size: 13px;
  }
}

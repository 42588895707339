/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

.home-container {
  overflow-x: hidden;
  /* font-family: "Montserrat", "sans-serif"; */
  /* background-color: #fafafa; */
  /* min-height: 100vh; */
  overflow: hidden;
  position: relative;
}

.background-circle {
  background-image: url('./Assets/dashboard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: 66px;
}

.blur-bg {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  /* height: calc(100vh - 66px); */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 66px;
  background-image: radial-gradient(150px 150px at 95% 0%, rgba(253, 186, 43, 0.3) 0%, rgba(253, 186, 43, 0) 100%), radial-gradient(150px 150px at 5% 0%, rgba(120, 213, 242, 0.3) 0%, rgba(253, 186, 43, 0) 100%);
}

.home-container-90 {
  overflow: hidden;
  width: 80%;
  margin: auto;
}

.home-container .img-on-text {
  position: absolute;
  padding-top: 66px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.scroll-down-btn {
  bottom: 50px;
  left: 0px;
}

.home-csp-heading h1 {
  color: #2c5363 !important;
  padding: 0;
}

.typewriter_heading {
  height: 50px;
}

.typewriter_heading h1 {
  margin-top: 15px;
  color: #16b287 !important;
}

.home-bottom-img {
  bottom: 11%;
  left: 250px;
  display: flex;
}

.home-bottom-img-div {
  width: 210px;
  height: 55px;
  background-color: transparent !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  margin: 40px 20px 20px 0;
  font-size: 11px;
  border-radius: 10px;
  /* padding-left: 10px !important; */
}

.home-bottom-img-div span {
  margin-left: 10px;
}

.home-svgs {
  margin: 0 !important;
  height: 49px !important;
  width: 49px !important;
}

.csp-main-heading-h1 {
  margin: 0;
  /* padding-top: 130px;
  padding-left: 70px; */
}

.csp-main-heading-h1 h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 1.1;
}

.csp-main-heading-h1 .csp-home-btn {
  background-color: #16b287;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14;
  font-weight: 500;
}

.csp-home-btn:hover .home-btn-arrow {
  transform: translateX(10px);
}

.csp-main-heading-h1 .csp-home-btn:hover {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  background-color: #16b287;
}

.start_up_ind {
  width: 70%;
}

.start_up_text {
  bottom: 12%;
  left: 9%;
}

.landing-para {
  width: 80%;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: justify;
}

.circle_imgs {
  height: 40px !important;
  width: 40px !important;
  margin: 0;
  padding: 0;
}

.home_rotate_container {
  margin: 0;
  padding-left: 50px !important;
}

.home_bg_circle {
  position: absolute;
  /* opacity: 0.8; */
  /* filter: grayscale(0%); */
  width: 440px !important;
  height: 440px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.home_rotate_circle {
  margin: 0 !important;
  padding: 0 !important;
  z-index: 1;
  width: 550px !important;
  height: 550px !important;
  -webkit-animation: spin 55s linear infinite;
  -moz-animation: spin 55s linear infinite;
  animation: spin 55s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.responsive-csp-quote {
  display: none;
}

#rotating_container {
  z-index: 1;
  width: 450px;
  height: 450px;
  margin: 100px auto;
  border: 8px solid #2c5363;
  display: grid;
  grid-template-columns: 60px;
  grid-template-rows: 60px;
  place-content: center;
  border-radius: 50%;
}

.home-circle-item {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  /* line-height: 30px; */
  text-align: center;
  border-radius: 50%;
  background-image: radial-gradient(#fff, #c1e3dc);
  /* background-image: radial-gradient(#fff8dc, #fcb845); */
  /* background-color: palevioletred; */
  height: 70px;
  width: 70px;
  animation: spin 54s var(--d, 0s) linear infinite;
  /* duration = 12s, numbor of item = 6 so a delay of 12/6 = 2s */
  transform: rotate(0) translate(225px) rotate(0);
}

@keyframes spin {
  100% {
    transform: rotate(1turn) translate(225px) rotate(-1turn);
  }
}

.cspin-incomefix {
  max-width: 600px;
}

.dotouwant {
  margin: 0px;
  font-size: 26px;
  padding: 10px 0;
}

.cspin-growth {
  padding-top: 80px;
  padding-bottom: 80px;
}

.csp-info p {
  font-size: 20px;
}

@media screen and (max-width: 1600px) {
  .csp-main-heading-h1 {
    margin: 0;
    /* padding-top: 150px;
    padding-left: 50px; */
  }

  .csp_quote {
    width: 600px !important;
  }

  .home-bottom-img {
    bottom: 6%;
    left: 190px;
    display: flex;
  }
}


@media screen and (max-width: 1400px) {
  .csp-main-heading-h1 {
    margin: 0;
    /* padding-top: 140px;
    padding-left: 40px; */
  }

  .home-bottom-img {
    bottom: 5%;
    left: 150px;
    display: flex;
  }

  .csp_quote {
    width: 550px !important;
  }

  .home_rotate_container {
    margin: 0;
    padding-left: 50px !important;
  }

  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 45 !important;
  }

  .typewriter_heading {
    height: 48px;
  }

  .typewriter_heading h1 {
    margin-top: 15px;
    color: #16b287 !important;
    font-size: 48px !important;
  }

  .home_bg_circle {
    position: absolute;
    width: 430px !important;
    height: 430px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #rotating_container {
    z-index: 1;
    width: 430px;
    height: 430px;
    margin: 100px auto;
    border: 8px solid #2c5363;
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 60px;
    place-content: center;
    border-radius: 50%;
  }

  .home-circle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1;
    text-align: center;
    border-radius: 50%;
    background-image: radial-gradient(#fff, #c1e3dc);
    height: 70px;
    width: 70px;
    animation: spin 54s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(215px) rotate(0);
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn) translate(215px) rotate(-1turn);
    }
  }
}

@media screen and (max-width: 1200px) {

  .csp-main-heading-h1 {
    margin: 0;
    /* padding-top: 90px !important;
    padding-left: 30px; */
  }

  .home-bottom-img {
    bottom: 15%;
    /* left: 75px; */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .csp_quote {
    width: 475px !important;
  }

  .home-container .img-on-text {
    position: absolute;
    padding-top: 0px;
  }

  .scroll-down-btn {
    bottom: 10px;
  }

  .home_rotate_container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 42px !important;
  }

  .typewriter_heading {
    height: 42px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .typewriter_heading h1 {
    margin-top: 15px;
    color: #16b287 !important;
    font-size: 42px !important;
  }

  .home_bg_circle {
    position: absolute;
    width: 400px !important;
    height: 400px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #rotating_container {
    z-index: 1;
    width: 400px;
    height: 400px;
    margin: 50px auto;
    border: 8px solid #2c5363;
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 60px;
    place-content: center;
    border-radius: 50%;
  }

  .home-circle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1;
    text-align: center;
    border-radius: 50%;
    background-image: radial-gradient(#fff, #c1e3dc);
    height: 70px;
    width: 70px;
    animation: spin 54s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(200px) rotate(0);
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn) translate(200px) rotate(-1turn);
    }
  }

}

@media screen and (max-width: 991px) {

  .large-devices {
    display: flex;
    flex-direction: column-reverse;
  }

  .csp-main-heading-h1 {
    margin: 0 !important;
    padding: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .csp_quote {
    display: none;
  }

  .scroll-down-btn {
    bottom: 30px;
  }

  .responsive-csp-quote {
    display: block !important;
    width: 500px !important;
    height: auto !important;
  }


  .home_rotate_container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 42px !important;
  }


  .landing-para p {
    font-size: 17px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .landing-para {
    padding: 30px !important;
    margin: 0;
    width: 100%;
    /* width: 80%; */
  }

  .home_bg_circle {
    position: absolute;
    width: 400px !important;
    height: 400px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .csp-main-heading-h1 .csp-home-btn {
    width: 170px;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
  }

  #rotating_container {
    z-index: 1;
    width: 400px;
    height: 400px;
    margin: 30px auto;
    border: 8px solid #2c5363;
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 60px;
    place-content: center;
    border-radius: 50%;
  }

  .home-circle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1;
    text-align: center;
    border-radius: 50%;
    background-image: radial-gradient(#fff, #c1e3dc);
    height: 70px;
    width: 70px;
    animation: spin 54s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(200px) rotate(0);
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn) translate(200px) rotate(-1turn);
    }
  }

  .home-bottom-img {
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 42px !important;
    text-align: center;
  }

  .typewriter_heading {
    height: 42px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .typewriter_heading h1 {
    margin-top: 15px;
    color: #16b287 !important;
    font-size: 42px !important;
  }

}

@media screen and (max-width: 768px) {
  .large-devices {
    display: flex;
    flex-direction: column-reverse;
  }

  .home-container .blur-bg {
    /* height: 100vh; */
  }

  .csp-main-heading-h1 {
    margin: 0;
    padding-top: 30px;
    padding-left: 10px;
    text-align: center;
  }

  .home_rotate_container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .typewriter_heading {
    height: 36px;
  }

  .typewriter_heading h1 {
    margin-top: 10px;
    color: #16b287 !important;
    font-size: 36px !important;
  }

  .home_bg_circle {
    position: absolute;
    width: 350px !important;
    height: 350px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #rotating_container {
    z-index: 1;
    width: 350px;
    height: 350px;
    margin: 50px auto;
    border: 8px solid #2c5363;
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 60px;
    place-content: center;
    border-radius: 50%;
  }

  .home-circle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1;
    text-align: center;
    border-radius: 50%;
    background-image: radial-gradient(#fff, #c1e3dc);
    height: 70px;
    width: 70px;
    animation: spin 54s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(175px) rotate(0);
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn) translate(175px) rotate(-1turn);
    }
  }

  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 36px !important;
    text-align: center;
  }

  .typewriter_heading {
    height: 36px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .typewriter_heading h1 {
    margin-top: 15px;
    color: #16b287 !important;
    font-size: 36px !important;
  }
}


@media screen and (max-width: 491px) {

  .home-bottom-img {
    bottom: -60px !important;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .home-bottom-img-div {
    width: 140px;
    height: 35px;
    background-color: transparent !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    margin: 40px 20px 20px 0;
    font-size: 11px;
    border-radius: 10px;
    /* padding-left: 10px !important; */
  }

  .home-bottom-img-div span {
    margin-left: 10px;
    font-size: 9px;
  }

  .home-svgs {
    margin: 0 !important;
    height: 30px !important;
    width: 30px !important;
  }

  .scroll-down-btn {
    bottom: 70px;
    left: 26%;
  }


  .home-container {
    height: auto;
  }

  .home-container-90 {
    margin: 0 !important;
    width: 100%;
  }

  .home-container .img-on-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .home-container .blur-bg {
    /* height: 100vh; */
  }

  .large-devices {
    display: flex;
    flex-direction: column-reverse;
  }

  .csp-main-heading-h1 {
    padding: 0 !important;
    margin: 0 !important;
  }

  .responsive-csp-quote {
    margin-top: 20px;
    display: block !important;
    width: 250px !important;
    height: auto !important;
  }

  .csp_quote {
    display: none;
  }

  .home_rotate_container {
    margin-top: 0px !important;
    padding: 0 !important;
  }


  .home_bg_circle {
    position: absolute;
    width: 250px !important;
    height: 250px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .circle_imgs {
    height: 35px !important;
    width: 35px !important;
    margin: 0;
    padding: 0;
  }


  #rotating_container {
    z-index: 1;
    width: 250px;
    height: 250px;
    margin: 50px auto;
    border: 8px solid #2c5363;
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 60px;
    place-content: center;
    border-radius: 50%;
  }

  .home-circle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1;
    text-align: center;
    border-radius: 50%;
    background-image: radial-gradient(#fff, #c1e3dc);
    height: 60px;
    width: 60px;
    animation: spin 54s var(--d, 0s) linear infinite;
    transform: rotate(0) translate(125px) rotate(0);
  }

  @keyframes spin {
    100% {
      transform: rotate(1turn) translate(125px) rotate(-1turn);
    }


  }

  .csp-main-heading-h1 .csp-home-btn {
    width: 200px;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
  }


  .landing-para p {
    font-size: 16px;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .landing-para {
    padding: 30px !important;
    margin: 0;
    width: 100%;
  }

  .responsive-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;

  }


  .home-csp-heading h1 {
    color: #2c5363 !important;
    padding: 0;
    font-size: 28px !important;
    text-align: center;
  }

  .typewriter_heading {
    height: 28px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .typewriter_heading h1 {
    margin-top: 15px;
    color: #16b287 !important;
    font-size: 28px !important;
    text-align: center;
  }



  .dotouwant {
    margin: 0px;
    font-size: 20px;
    padding: 10px 0;
  }

  .cspin-growth {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .csp-info {
    text-align: justify;
    padding: 0 20px;
  }

  .csp-info p {
    font-size: 17px;
  }

  .cspin-incomefix {
    max-width: 300px;
    height: 73px;
    padding: 25px 0;
  }

  .join-now-home {
    text-align: center;
  }

  .tenth-section-content {
    text-align: center;

  }

}
.latest-blog {
  background-image: url("../Assets/latest-blog.png");
  background-repeat: no-repeat;
}

.latest-blog-card {
  margin-top: 100px;
  /* margin-left: 50px; */
  width: 650px;
  height: 400px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000029;
  overflow: hidden;
}

.latest-blog-card h5 {
  font-size: 18px;
  font-weight: 600;
  color: #2c5363;
}

.latest-blog-card img {
  width: 100%;
  height: 360px;
  border-radius: 5px;
  /* transition: all 1s ease-in-out; */
  transition: transform 0.5s ease;
  transform-origin: 50% 50%;
}

.latest-blog-card img:hover {
  /* -webkit-transform: scale(1.1, 1.1); */
  transform: scale(1.1);
}

.latest-blog .blog-desc {
  margin-top: 100px !important;
  margin-right: 50px !important;
}

.latest-blog .blog-desc h3 a {
  text-decoration: none;
  color: #2c5363;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
}

.latest-blog .blog-desc h3 a:hover {
  color: #16b287;
  transition: 0.5s;
}

.latest-blog .blog-desc p {
  font-size: 16px;
  width: 100%;
  height: 140px !important;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.latest-blog .blog-desc button {
  background-color: #2c5363;
  color: white;
  width: 170px;
}

.latest-blog .blog-desc button:hover,
.blog-span:hover {
  color: #16b287;
  transition: 0.3s;
}

.knowledge-main-container .latest-blog .blog-desc button,
.blog-span:hover {
  padding-left: 10px;
}

.small-devices-blog-heading {
  display: none !important;
}

.large-devices-blog-heading {
  display: block;
}

@media screen and (max-width: 1200px) {
  .latest-blog {
    background-size: 45%;
  }
  .latest-blog-card {
    margin-top: 80px;
    margin-left: 40px;
    width: 550px;
    height: 320px;
  }
  .latest-blog-card img {
    width: 100%;
    height: 280px;
  }
}

@media screen and (max-width: 991px) {
  .latest-blog {
    margin: 0px !important;
  }
  .latest-blog .blog-desc {
    margin-top: 30px;
    margin-right: 0 !important;
    padding: 0 10px;
  }

  .latest-blog .blog-desc p {
    font-size: 16px;
    width: 100%;
    height: 120px !important;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .latest-blog .blog-desc h3 a {
    font-size: 25px;
  }

  .latest-blog .blog-desc {
    margin-top: 80px !important;
    margin-right: 0 !important;
  }

  .latest-blog .blog-desc p {
    font-size: 16px;
    margin: 0 !important;
    /* text-align: justify; */
  }
}

@media screen and (max-width: 768px) {
  .latest-blog {
    background-size: 65%;
  }
  .latest-blog-card {
    margin-top: 60px;
    margin-left: 30px;
    width: 500px;
    height: 300px;
  }
  .latest-blog-card img {
    width: 100%;
    height: 250px;
  }

  .latest-blog .blog-desc {
    margin-top: 80px !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .latest-blog {
    background-size: 70%;
  }

  .latest-blog-card {
    margin-top: 60px;
    margin-left: 30px;
    width: 400px;
    height: 270px;
  }

  .latest-blog-card img {
    width: 100%;
    height: 220px;
  }
  .latest-blog .blog-desc p {
    font-size: 16px;
    width: 100%;
    height: 100px !important;
    margin: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .latest-blog .blog-desc {
    margin-top: 70px !important;
    margin-right: 0 !important;
  }

  .small-devices-blog-heading {
    display: block !important;
    text-align: center;
  }

  .latest-blog .blog-desc h3 a {
    font-size: 22px;
  }

  .latest-blog .blog-desc p {
    font-size: 13px;
    text-align: justify;
    margin-top: 20px;
  }

  .large-devices-blog-heading {
    display: none !important;
  }
}

@media screen and (max-width: 491px) {
  .latest-blog-card {
    margin-top: 60px;
    margin-left: 5px;
    width: 300px;
    height: 170px;
  }

  .latest-blog-card img {
    width: 100%;
    height: 130px;
  }

  .latest-blog .blog-desc {
    margin-top: 50px !important;
    margin-right: 0 !important;
  }
}

.banking-container {
  padding: 105px 0 50px 0;
}

.banking-main-container {
  width: 75%;
  margin: auto;
}

.banking-main-container h1 {
  font-size: 68px;
  font-weight: 700;
  color: #00b087;
}

.banking-main-container h5 {
  font-size: 16px;
  line-height: 1.5;
  /* font-weight: 600; */
  color: #2c5363;
  text-align: justify;
  margin-right: 30px;
  margin-left: 5px;
}

/* banking-sub-container */


/* .banking-sub-main-container {
  width: 90%;
  margin: auto;
} */

.banking-sub-main-container p {
  font-size: 17px !important;
  color: #2c5363 !important;
}

.banking-sub-main-container h2 {
  font-size: 30.155px;
  font-weight: 700;
}

.banking-sub-main-container .bank-services {
  /* background-color: #f8f9fa; */
  border-radius: 3px;
}

/* .banking-sub-main-container .bank-services:hover {
  background-color: #2c5363;
  color:#2c5363;
} */

.flaticon {
  color: #2c5363;
  font-size: 50px;
  padding: 30px;
}

/* .banking-sub-main-container p {
  font-size: 18px;
  font-weight: 600;
} */

.icon-services-container {
  background-image: linear-gradient(white, #ebfffa) !important;
}

.services-btn {
  background-color: #2c5363 !important;
  margin-left: 5px;
}


@media screen and (max-width: 600px) {
  .banking-sub-main-container .bank-services .flaticon {
    font-size: 30px !important;
    padding: 12px;
  }

  .banking-sub-main-container p {
    font-size: 10px !important;
  }
}
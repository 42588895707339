.aadhar-container {
    margin-top: 65px;
    font-family: "Open Sans", sans-serif;
  }
  
  .aadhar {
    width: 75%;
    margin: auto;
  }
  
  .aadhar-container h1 {
    font-size: 36px;
    color: #333;
    padding: 80px 0 35px 0 !important;
  }
  
  .aadhar-container p {
    color: #666;
    font-size: 16px;
    text-align: justify;
    line-height: 30px;
  }
  
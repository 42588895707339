.financial-container{
  padding: 100px 0 80px 0;
}

.financial-main-container {
  width: 75%;
  margin: auto;
}

.financial-main-container h1 {
  font-size: 68px;
  font-weight: 700;
  color: #00b087;
}

.financial-main-container h5 {
  /* font-size: 24px;
  font-weight: 600;
  color: #2c5363; */
  font-size: 16px;
  line-height: 1.5;
  color: #2c5363;
  text-align: justify;
  margin-right: 30px;
  margin-left: 5px;
}

/* .financial-sub-container {
  background-color: #2c5363;
}

.financial-sub-main-container {
  width: 90%;
  margin: auto;
} */

.financial-sub-main-container p {
  font-size: 17px !important;
  color: #2c5363 !important;
}

.financial-sub-main-container h2 {
  font-size: 30.155px;
  font-weight: 700;
}

.financial-sub-main-container .financial-services {
  border-radius: 3px;
}

.flaticon {
  color: #2c5363;
  font-size: 50px;
  padding: 30px;
}


@media screen and (max-width: 600px) {
  .financial-sub-main-container .financial-services .flaticon {
    font-size: 30px !important;
    padding: 12px;
  }

  .financial-sub-main-container p {
    font-size: 10px !important;
  }
}
.knowledge{
    margin-top: 61px;
}


@media screen and (max-width : 992px) {
    .knowledge{
        margin-top: 55px;
    }
    
}
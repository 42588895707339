@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.navbar {
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1); */
  background-color: white !important;
  font-family: "Montserrat", "sans-serif";
  /* padding: 12px 0 !important; */
  padding: 0.719rem 0 !important;
}

.brand-span-div {
  margin-top: 5px;
}

.brand-span {
  line-height: 1;
  font-size: 11px;
  padding: 0 2px;
  margin: 0;
  font-weight: 500;
  border-right: 1px solid #325867;
}
.brand-span-last{
  line-height: 1;
  font-size: 11px;
  padding: 0 2px;
  margin: 0;
  font-weight: 500;
}
.logo-img{
  margin-left: 28px;
}

/* .container {
  width: 80%;
  margin: auto;
} */

.navbar a {
  text-decoration: none;
  color: #6c757d !important;
}

.navbar a:hover {
  /* color: #586067; */
  color: #2c5363 !important;
}

.nav-item {
  padding: 0 15px;
}

.navbar-nav .nav-item:hover {
  background-color: #efefef;
  border-radius: 5px;
}

.navbar-nav .nav-item.active {
  background-color: #efefef;
  border-radius: 5px;
}

.nav-link {
  padding: 0.1rem 0.1rem !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #6c757d;
  /* font-weight: 500; */
}

/* .nav-link-active {
  padding: 0.1rem 0.1rem !important;
  font-size: 17px;
  color: #6c757d;
} */

.navbar .nav-item:hover .dropdown-menu {
  display: block;
  left: 0;
}

.dropdown-item.active,
.dropdown-item:active{
  background-color: #16b287;
}
/* .navbar .dropdown-menu>li>a:focus,
.navbar .dropdown-menu>li>a:hover {
  background-color: #efefef !important;
  color: #2c5363 !important;
} */

.navbar .log-btn {
  background-color: #00b087;
  color: white !important;
  font-weight: 600;
  margin: 0 0 0 15px;
  width: 130px;
}

.navbar .log-btn:focus {
  box-shadow: none;
}


.nav-item .nav-drop-ico {
  margin-right: 10px;
}

/* .nav-link.active {
  color: #2c5363 !important;
  font-weight: 600;
} */

.navbar-toggler {
  color: rgba(0, 0, 0, .55);
  border: none !important;
  padding: 0;
  margin-bottom: 10px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  text-decoration: none;
  outline: 0 !important;
  border: none !important;
}

/* toggle icon */
/* Initially, display the 'hamburger' icon */
.navbar-toggler-icon::before {
  content: '' !important;
  background-image: url(./Assets/ham.svg);
  background-size: cover;
  width: 40px;
  height: 40px;
  display: inline-block;
}

/* When the menu is expanded, display the 'X' icon */
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
  content: '';
  background-image: url(./Assets/closeCircle.svg);
  background-size: cover;
  width: 40px;
  height: 40px;
  display: inline-block;
}

/* When the menu is collapsed, display the 'hamburger' icon */
.navbar-toggler[aria-expanded="false"] .navbar-toggler-icon::before {
  content: '';
  background-image: url(./Assets/ham.svg);
  background-size: cover;
  width: 40px;
  height: 40px;
  display: inline-block;
}

.navbar-toggler-icon {
  background-image: none !important;
}

/* .toggler-for-navbar {
  height: 25px !important;
  width: 25px !important;
  border-radius: 2px;
  padding: 5px !important;
  background-color: #efeeee;
} */

/* .navbar-toggler button:focus {
  box-shadow: none;
} */

button:focus {
  /* box-shadow: none !important; */
}


.home-nav-item {
  display: none !important;
}

.login-mobile-btn {
  display: none !important;
}

/* @media screen and (max-width: 768px) {
  .navbar-container .container-fluid {
    margin: 0 !important;
  }
} */

@media screen and (max-width: 992px) {
  .navbar-container .container-fluid {
    margin: 0 !important;
    padding: 0 20px !important;
  }

  .navbar img {
    padding: 2px;
    height: 30px;
  }

  .home-nav-item {
    margin-top: 15px !important;
    display: block !important;
  }

  .home-nav-item.active {
    background-color: #efefef;
    border-radius: 5px;
  }

  .login-mobile-hidden {
    display: none;
  }

  .navbar-nav .fa {
    font-size: 22px;
  }

  .navbar-nav .nav-item {
    margin-top: 5px !important;
  }

  .mobile-view-btns {
    margin-top: 10px;
    margin-left: 10px !important;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }

  .navbar .log-btn {
    width: 150px !important;
    margin: 0;
  }

  .mobile-view-btns .login-mobile-btn {
    width: 150px !important;
    display: block !important;
    background-color: white !important;
    color: #16b287 !important;
    font-weight: 600 !important;
    border: 1px solid #16b287 !important;
  }
}
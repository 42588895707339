.partner-main-container {
  margin: auto;
  width: 80%;
}

.partner-container {
  background-color: white !important;
  /* background-color: red; */
}

.partner-container h2 {
  font-weight: 700;
  font-size: 30.155px;
  /* padding: 30px 0 30px 0; */
  color: #2c5363;
  margin-left: 280px;
}

.partner-companies  {
  margin-top: 70px;
}

.partner-companies .slider-items .item img {
  width: 170px;
  /* filter: grayscale(100%); */
}

.partner-companies .slider-items .item .bbps-logo {
  width: 130px !important;
  height: 40px !important;
  margin-top: 10px;
}
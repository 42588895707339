.media-container {
  margin-top: 65px;
  background-image: url(./media.png) !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.media-head {
  width: 90%;
  margin: auto;
}

.media-container h3 {
  padding: 150px 0;
  font-size: 40px;
  font-weight: 700;
  color: #36677b;
}

.media-head .media-card-img {
  height: 300px;
  background-image: linear-gradient(to right, #e5f7ff 0%, #ffffff 100%);
  box-shadow: 0px 10px 20px #00000029 !important;
}

.media-head .media-card-content {
  height: 300px;
  background-color: #2c5363;
}

.media-head .media-card-content .btn {
  background-color: #16b287;
  font-size: 12px;
}

.media-head .media-card-content h5 {
  font-size: 20px;
  font-weight: 500;
}

.media-head .media-card-content p {
  font-size: 15px;
  text-align: justify;
}

/* .digital-stamping-sec3-card::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0px;
  width: 0px;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
  border-right: 20px solid #2C5363;
  border-bottom: 20px solid #2C5363;
} */

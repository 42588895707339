.payment-container {
  padding: 100px 0 80px 0;
}

.payment-main-container {
  width: 75%;
  margin: auto;
}

.payment-sub-container-2 {
  width: 83%;
  margin: auto;
}

.payment-main-container h1 {
  font-size: 68px;
  font-weight: 700;
  color: #00b087;
}

.payment-main-container h5 {
  font-size: 16px;
  line-height: 1.5;
  color: #2c5363;
  text-align: justify;
  margin-right: 30px;
  margin-left: 5px;
}

.payment-sub-main-container p {
  font-size: 17px !important;
  color: #2c5363 !important;
}

.payment-sub-main-container h2 {
  font-size: 30.155px;
  font-weight: 700;
}

.payment-sub-main-container .payment-services {
  border-radius: 3px;
}

.flaticon {
  color: #2c5363;
  font-size: 50px;
  padding: 30px;
}

.payment-sub-main-container p {
  font-size: 18px;
}
.more-icon{
  padding: 30px;
}

.more-icon img{
  height: 50px;
  width: 50px;
}


@media screen and (max-width: 600px) {
  .payment-sub-main-container .payment-services .flaticon {
    font-size: 30px !important;
    padding: 12px;
  }

  .payment-sub-main-container p {
    font-size: 10px !important;
  }
}
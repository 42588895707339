.main-travelsegment {
    padding: 100px 0;
}

.main-travelsegment h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
}
.main-travelsegment span {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #00B087;
}

.travelsegment-content{
    padding: 20px 0 ;
}

.travelsegment-content h2{
    font-size: 24px;
    font-weight: 600;
    padding: 10px 0;
}
.travelsegment-content p{
    font-size: 18px;
}

.travelsegment-1st{
    padding: 30px;
    height: 100%;
    background-color: #effaff;
    border-radius: 5px;
}
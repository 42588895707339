.ourSolutions-container {
  background-color: #f8fcff;
  padding: 48px 0 !important;
  margin: 0;
  box-sizing: border-box;
}

.ourSolutions-main-container {
  width: 90%;
  margin: auto;
}

.ourSolutions-main-container h2 {
  color: #2c5363;
  font-size: 30px;
  font-weight: 700;
}

.solutions-head-content {
  width: 75% !important;
}

.ourSolutions-main-container h5 {
  color: #2c5363;
  font-size: 30px;
  font-weight: 600;
}

.ourSolutions-main-container p {
  font-size: 18px;
}

.ourSolutions-main-container img {
  width: 450px;
  border: 12px solid black;
  border-radius: 10px;
  box-shadow: -15px 15px 0px lightblue;
  z-index: 2;
}

.ourSolutions-main-container .left-side img {
  width: 450px;
  border: 12px solid black;
  border-radius: 10px;
  box-shadow: 15px 15px 0px lightblue;
  z-index: 2;
}

.rounded-circle {
  background-color: #e5f7ff;
  top: 0;
  right: 0;
  height: 200px;
  width: 200px;
}

.left-circle {
  background-color: #e5f7ff;
  top: 0;
  left: 0;
  height: 200px;
  width: 200px;
}

@media screen and (max-width: 992px) {
  .solution-reverse-column {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 768px) {
  .solution-reverse-column {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 600px) {
  .ourSolutions-main-container img {
    width: 350px !important;
    border: 10px solid black;
    /* border: none; */
  }

  .solution-reverse-column {
    display: flex;
    flex-direction: column-reverse;
  }

  .ourSolutions-main-container h2 {
    display: none;
  }

  .ourSolutions-main-container .left-side img {
    width: 350px !important;
    border: 10px solid black;
  }

  .rounded-circle {
    display: none !important;
  }

  .solution-reverse-column {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 491px) {
  .ourSolutions-main-container img {
    width: 270px !important;
    border: 12px solid black;
    /* border: none; */
  }

  .ourSolutions-main-container .left-side img {
    width: 270px !important;
    border: 12px solid black;
    /* border: none; */
  }

  .rounded-circle {
    display: none;
  }

  .solutions-head-content {
    width: 100% !important;
    padding: 0 20px;
  }

  .ourSolutions-main-container h5 {
    font-size: 24px;
    text-align: center;
  }

  .ourSolutions-main-container p {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
}

.travelreq-home-container {
    margin-top: 61px;
    margin: auto;
    padding: 50px 10px;
  }
  
  
  .travelreq-left-content h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.4;
  }
  
  .travelreq-left-content span {
    color: #00b087;
  }
  
  
  /* .travelreq-left-content p {
      width: 600px;
    } */
  
  .travelreq-left-content .pdiv {
    margin-top: 30px;
  }
  
  /* .travelreq-home-container img {
      width: 400px;
    } */
  
  .about-home-content {
      text-align: justify;
  }
  
  .bankingreq-home-list li {
    font-size: 18px;
    color: #000;
    list-style: circle;
    padding: 5px;
  }
  
  .travelreq-home-img {
    width: 400px !important;
    height: 400px !important;
    /* margin-right: 50px; */
  }
  
  .small-devices-about-heading {
    display: none;
  }
  
  
  
  /* BankingReq.css */
  .item-list {
    list-style: none;
    padding: 0;
  }
  
  .item-list li {
    padding: 20px 13px;
    cursor: pointer;
  }
  
  .item-list li h6 {
    font-size: 22px;
  }
  
  .item-list li.active {
    background-color: #EBFFFA;
  }
  
  .item-list li p {
    margin-top: 10px;
  }
  
  .expanded-paragraph {
    color: #000;
    font-weight: 600;
  
  }
  
  .rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .travelreq-bottom {
    background-color: #2C5363;
    padding: 40px 100px;
  }
  
  .travelreq-bottom h2 {
    color: #00B087;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }
  
  .travelreq-bottom h4 {
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }
  
  
  @media screen and (max-width: 1600px) {
    .travelreq-home-img {
      width: 500px;
      height: 300px;
      /* margin-right: 50px; */
    }
  }
  
  @media screen and (max-width: 1200px) {
    .travelreq-home-img {
      width: 400px !important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .travelreq-home-container {
      margin-top: 55px;
    }
  
    .travelreq-left-content h2 {
      font-size: 35px;
      text-align: center;
    }
  
    .travelreq-left-content p {
      text-align: center;
      font-size: 16px;
    }
  
    .about-home-content {
      width: 100%;
    }
  
    .travelreq-home-img {
      width: 425px;
      margin: 0 !important;
    }
  
    .travelreq-bottom {
      background-color: #2C5363;
      padding: 40px 0;
      /* margin-top: 50px; */
    }
  
    .travelreq-bottom h2 {
      color: #00B087;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  
    .travelreq-bottom h4 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
  
  @media screen and (max-width: 768px) {
    .bankingreq-main-container {
      padding: 30px 0 !important;
    }
  
    .travelreq-home-img {
      width: 400px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .bankingreq-main-container {
      padding: 30px 0 !important;
    }
  
    .travelreq-left-content h2 {
      font-size: 30px;
      text-align: center;
    }
  
    .travelreq-home-img {
      width: 350px;
      margin: 0 !important;
    }
  
    .travelreq-bottom {
      background-color: #2C5363;
      padding: 40px 0;
    }
  
    .travelreq-bottom h2 {
      color: #00B087;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  
    .travelreq-bottom h4 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
  
  
  @media screen and (max-width: 491px) {
    .travelreq-home-img {
      width: 450px !important;
      height: 200px !important;
      margin-right: 100px;
    }
  
    .travelreq-home-container {
      margin-top: 55px;
    }
  
    .bankingreq-main-container {
      padding: 20px 0 !important;
    }
  
    .travelreq-left-content h2 {
      font-size: 30px;
      text-align: center;
    }
  
    .travelreq-left-content p {
      font-size: 15px;
    }
  
    .large-devices-about-heading {
      display: none !important;
    }
  
    .small-devices-about-heading {
      display: block !important;
    }
  
    .travelreq-home-img {
      width: 250px !important;
      margin: 0 !important;
    }
  
    .travelreq-bottom {
      background-color: #2C5363;
      padding: 40px 20px;
      /* margin-top: 180px; */
    }
  
    .travelreq-bottom h2 {
      color: #00B087;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  
    .travelreq-bottom h4 {
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }